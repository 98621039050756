import { createTheme } from "@material-ui/core";

const theme = createTheme({
  palette: {
    info: { main: "#262626" },
    primary: {
      main: "#1967D2",
    },
    secondary: {
      main: "#ffffff",
    },
  },
});

export default theme;
