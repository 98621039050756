import { Grid } from "@mui/material";
import React from "react";
import Footer from "./components/Footer";
import Navbar from "./components/NavBar";
import LandingPage from "./pages/LandingPage";

const App: React.FC = () => {
  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <Navbar />
      </Grid>
      <Grid item container>
        <Grid item xs={false} sm={2} />
        <Grid item xs={12} sm={8}>
          <LandingPage />
          <Footer />
        </Grid>
        <Grid item xs={false} sm={2} />
      </Grid>
    </Grid>
  );
};

export default App;
