import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from "@mui/material";
import React from "react";

const LandingPage: React.FC = () => {
  return (
    <Grid container spacing={8} justifyContent={"space-around"}>
      <Grid item>
        <Card sx={{ maxWidth: 345, height: 400 }} raised>
          <CardActionArea>
            <CardMedia
              component="img"
              height="200"
              image="https://images.unsplash.com/photo-1506703719100-a0f3a48c0f86?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Who are we?
              </Typography>
              <Typography variant="body2" color="text.secondary">
                The Galactic Coders are just a bunch of people happy to code,
                collaborate and spread knowledge about all things code! Our
                family grows everyday and we have a wide variety of skillsets
                such as React, Node, TypeScript, Go, SQL, React Native C#... The
                list goes on...
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
      <Grid item>
        <Card sx={{ maxWidth: 345, height: 400 }} raised>
          <CardActionArea>
            <CardMedia
              component="img"
              height="200"
              image={
                "https://images.unsplash.com/photo-1618401471353-b98afee0b2eb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1776&q=80"
              }
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                What we do!
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Mostly we code. Sometimes we play board games and drink beers.
                Sometimes we juts hang out. But most of all we code. You can
                check out what we do <a>here</a>
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    </Grid>
  );
};

export default LandingPage;
